import * as React from "react"
import headshot from '../images/headshot.jpg'

export default function About() {
  return(
    <div className="space-y-6">

      {/* <div className="md:hidden flex justify-center">
        <div className="flex-none">
          <img src={headshot} className="h-48" /> 
        </div>
      </div> */}

      <div className="text-left space-y-6">
        <h1 className="text-3xl font-semibold">
          Hi! 👋
        </h1>
        <div className="md:flex">
          <div className="md:text-left md:pr-12 lg:pr-20 space-y-4">
            <p>
              I’m Julia, a senior at the Massachusetts Institute of Technology studying computer science and urban science & planning with a minor in entrepreneurship & innovation.
            </p>
            <p>
              I’m most interested in the intersection of product development, software design, and cities — specifically, human-centered design and building software for social good. At MIT, I’m currently involved in <a href="http://asianamerican.mit.edu/" className="text-blue-600 underline">Asian American Initiative</a>, <a href="https://dancetroupe.mit.edu" className="text-blue-600 underline">DanceTroupe</a>, and <a href="http://mitfsa.mit.edu/" className="text-blue-600 underline">Filipino Students Association</a>, and was previously a part of <a href="http://eti.mit.edu" className="text-blue-600 underline">Ethical Technology Initiative</a>, <a href="http://codeforgood.mit.edu/" className="text-blue-600 underline">Code for Good</a>, <a href="http://startup.mit.edu" className="text-blue-600 underline">StartLabs</a>, and <a href="https://www.mit-divest.com/" className="text-blue-600 underline">Divest</a>.
            </p>
            <p>
              I most recently worked on product management at <a href="https://suno.com" className="text-blue-600 underline">Suno AI</a> and was a research assistant in MIT CSAIL's <a href="http://sdg.csail.mit.edu" className="text-blue-600 underline">Software Design Group</a>. I was also an Innovation Fellow at <a href="https://www.mass.gov/the-lab-massdot" className="text-blue-600 underline">The Lab @ MassDOT</a>, worked on policy at <a href="https://t4america.org" className="text-blue-600 underline">Transportation for America</a>, and was a 2022 <a href="https://www.codingitforward.com/" className="text-blue-600 underline">Civic Innovation Corps</a> Fellow at the City of Boston, where I worked on data analysis and visualization. I've also worked in MIT's <a href="https://dataplusfeminism.mit.edu" className="text-blue-600 underline">Data + Feminism Lab</a> and <a href="https://civicdatadesignlab.mit.edu" className="text-blue-600 underline">Civic Data Design Lab</a>.
            </p>
            <p>
              In my free time, I enjoy playing tennis and basketball, searching for new books on Goodreads, cooking Filipino recipes, trying to get better at hip-hop dance, and exploring places via bus, train, or by foot. 
            </p>
            <p>
              You can reach me at jcamac [at] mit [dot] edu or find me on <a href="https://www.linkedin.com/in/juliaccamacho/" className="text-blue-600 underline">LinkedIn</a> and <a href="https://github.com/juliacamacho" className="text-blue-600 underline">Github</a>.
            </p>
          </div>
          <div className="hidden md:block justify-left space-y-2">
            <div className="w-72">
              <img src={headshot} className="flex-none" /> 
            </div>
            <p className="text-right text-xs">
              Me, circa 2023 :)
            </p>
          </div>
        </div>

      </div>

    </div>
  )
}
