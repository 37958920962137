import * as React from "react"
import Layout from '../../components/layout'
import { Link } from "gatsby"
import portersq from '../../images/portersq.jpg'
import brookline from '../../images/brookline.jpg'
import mitpress from '../../images/mitpress.jpg'
import brattle from '../../images/brattle.jpg'
import commonwealth from '../../images/commonwealth.jpg'
import trident from '../../images/trident.jpg'
import harvard from '../../images/harvard.jpg'
import arvida from '../../images/arvida.jpg'
import morethanwords from '../../images/morethanwords.jpg'
import beacon from '../../images/beacon.jpg'
import porterboston from '../../images/porterboston.jpg'
import sevenstars from '../../images/sevenstars.jpg'
import athenaeum from '../../images/athenaeum.jpeg'
import bluestockings from '../../images/bluestockings.jpeg'
import bridgest from '../../images/bridgest.jpeg'
import eastcity from '../../images/eastcity.jpeg'
import littledistrict from '../../images/littledistrict.jpeg'
import capitolhill from '../../images/capitolhill.jpg'
import solidstate from '../../images/solidstate.jpg'
import politicsprose from '../../images/politicsprose.jpg'
import mcnally from '../../images/mcnally.jpeg'
import raven from '../../images/raven.jpeg'
import strand from '../../images/strand.jpeg'
import symposium from '../../images/symposium.jpeg'
import last from '../../images/last.jpg'
import yume from '../../images/yume.jpg'
import maktaba from '../../images/maktaba.jpg'
import bertrand from '../../images/bertrand.jpg'
import capitaine from '../../images/capitaine.jpg'
import booksinc from '../../images/booksinc.jpg'
import dogeared from '../../images/dogeared.jpg'
import citylights from '../../images/citylights.jpg'
import { polygonLength } from "d3"


export default function Bookstores() {
  return(
    <Layout>

      <Link to="/" state={{ item: 'writing' }} className="flex space-x-2 items-center hover:underline mb-7">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <div >
          back to all reading
        </div>
      </Link>

      <div className="text-2xl font-bold mb-6">
        bookstores of boston (& beyond)
      </div>

      <div className="mb-6">
        on a mission to visit as many bookstores and libraries as possible. here are the ones I've visited so far:
      </div>

      {/* BOSTON */}
      <div className="text-xl font-bold mb-6">
        greater boston
      </div>

      <div className="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-16">

        <div className="space-y-12">

          <div className="space-y-3">
            <img src={raven} className=""/>
            <div className="text-xl font-semibold pt-2">
              raven used books, harvard square in cambridge MA (2/3/23)
            </div>
            <div>
              3/5 stars for nonfiction; 1/5 stars for fiction. Bought <span className="italic">Good City Form</span> by Kevin Lynch at a wonderfully steep discount.
            </div>
          </div>

          <div className="space-y-3">
            <img src={porterboston} className=""/>
            <div className="text-xl font-semibold pt-2">
              porter square books: boston edition, seaport in boston MA (11/19/22)
            </div>
            <div>
              3/5 stars for nonfiction; 4/5 stars for fiction. Picked up <span className="italic">Walkable City</span> by Jeff Speck.
            </div>
          </div>

          <div className="space-y-3">
            <img src={trident} className=""/>
            <div className="text-xl font-semibold pt-2">
              trident booksellers & cafe, back bay in boston MA (8/7/22)
            </div>
            <div>
              3/5 stars for nonfiction; 4/5 stars for fiction. Picked up <span className="italic">Severance</span> by Ling Ma and <span className="italic">There's a Revolution Outside, My Love</span>, edited by Tracy K. Smith and John Freeman.
            </div>
          </div>

          <div className="space-y-3">
            <img src={commonwealth} className=""/>
            <div className="text-xl font-semibold pt-2">
              commonwealth books, downtown crossing in boston MA (3/23/22)
            </div>
            <div>
              3/5 stars for nonfiction; 3/5 stars for fiction. Was intrigued by (but did not buy) <span className="italic">Learning Capitalist Culture: Deep in the Heart of Tejas</span> by Douglas Foley.
            </div>
          </div>

          <div className="space-y-3">
            <img src={brookline} className=""/>
            <div className="text-xl font-semibold pt-2">
              brookline booksmith, brookline MA (1/23/22)
            </div>
            <div>
              2/5 stars for nonfiction; 4/5 stars for fiction.
            </div>
          </div>

          <div className="space-y-3">
            <img src={mitpress} className=""/>
            <div className="text-xl font-semibold pt-2">
              mit press bookstore, kendall square in cambridge MA (11/24/21)
            </div>
            <div>
              5/5 stars for nonfiction; 1/5 for fiction. (Bonus points for the surround-sound rumbling of the Red Line every ten minutes.) Got <span className="italic">The Smart Enough City</span> by Ben Green. 
            </div>
          </div>

        </div>

        <div className="space-y-12">
          
          <div className="space-y-3">
            <img src={beacon} className=""/>
            <div className="text-xl font-semibold pt-2">
              beacon hill books & cafe, beacon hill in boston MA (12/17/22)
            </div>
            <div>
              1/5 stars for nonfiction; 3/5 stars for fiction.
            </div>
          </div>

          <div className="space-y-3">
            <img src={sevenstars} className=""/>
            <div className="text-xl font-semibold pt-2">
              seven stars bookstore, central square in cambridge MA (9/10/22)
            </div>
            <div>
              2/5 stars for nonfiction; 3/5 stars for fiction. 
            </div>
          </div>

          <div className="space-y-3">
            <img src={brattle} className=""/>
            <div className="text-xl font-semibold pt-2">
              brattle book shop, downtown crossing in boston MA (3/23/22)
            </div>
            <div>
              3/5 stars for nonfiction; 2/5 stars for fiction. Bought <span className="italic">Triumph of the City</span> by Ed Glaeser for a few dollars.
            </div>
          </div>

          <div className="space-y-3">
            <img src={morethanwords} className=""/>
            <div className="text-xl font-semibold pt-2">
              more than words books, waltham MA (3/22/22)
            </div>
            <div>
              3/5 stars for nonfiction; 2/5 stars for fiction.
            </div>
          </div>

          <div className="space-y-3">
            <img src={portersq} className=""/>
            <div className="text-xl font-semibold pt-2">
              porter square books, porter square in cambridge MA (1/16/22)
            </div>
            <div>
              4/5 stars for nonfiction; 4/5 stars for fiction. Picked up <span className="italic">The Privatization of Everything</span> by Donald Cohen and Allen Mikaelian.
            </div>
          </div>

          <div className="space-y-3">
            {/* <div className="text-pink-600 font-bold">[need to go back and get a photo]</div> */}
            <img src={harvard} className=""/>
            <div className="text-xl font-semibold pt-2">
              harvard book store, harvard square in cambridge MA (9/24/21)
            </div>
            <div>
              4/5 stars for nonfiction; 2/5 stars for fiction.
            </div>
          </div>

        </div>

      </div>


      {/* NYC */}
      <div className="text-xl font-bold mt-12 mb-6">
        nyc
      </div>

      <div className="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-16">

        <div className="space-y-12">

          <div className="space-y-3">
            <img src={yume} className=""/>
            <div className="text-xl font-semibold pt-2">
              yu & me books, manhattan in nyc (7/13/24)
            </div>
            <div>
              4/5 stars for nonfiction; 3/5 stars for fiction. Purchased <span className="italic">But What Will People Say?</span> by Sahaj Kaur Kohli. 
            </div>
          </div>
          
          <div className="space-y-3">
            <img src={strand} className=""/>
            <div className="text-xl font-semibold pt-2">
              the strand, manhattan in nyc (3/31/23)
            </div>
            <div>
              3/5 stars for nonfiction; 3/5 stars for fiction. Picked up <span className="italic">Transit Maps of the World</span> by Mark Ovenden.
            </div>
          </div>
          
        </div>

        <div className="space-y-12">
          
          <div className="space-y-3">
            <img src={mcnally} className=""/>
            <div className="text-xl font-semibold pt-2">
              mcnally jackson, manhattan in nyc (4/1/23)
            </div>
            <div>
              4/5 stars for nonfiction; 4/5 stars for fiction. 
            </div>
          </div>
          
          <div className="space-y-3">
            <img src={bluestockings} className=""/>
            <div className="text-xl font-semibold pt-2">
              bluestockings, manhattan in nyc (3/31/23)
            </div>
            <div>
              4/5 stars for nonfiction; 1/5 stars for fiction. 
            </div>
          </div>

        </div>

      </div>


      {/* WASHINGTON DC */}
      <div className="text-xl font-bold mt-12 mb-6">
        washington dc
      </div>

      <div className="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-16">

        <div className="space-y-12">

          <div className="space-y-3">
            <img src={politicsprose} className=""/>
            <div className="text-xl font-semibold pt-2">
              politics and prose at union market, union market in washington dc (7/9/23)
            </div>
            <div>
              4/5 stars for nonfiction; 2/5 stars for fiction. 
            </div>
          </div>
          
          <div className="space-y-3">
            <img src={solidstate} className=""/>
            <div className="text-xl font-semibold pt-2">
              solid state books, h street in washington dc (7/3/23)
            </div>
            <div>
              4/5 stars for nonfiction; 4/5 stars for fiction. 
            </div>
          </div>

          <div className="space-y-3">
            <img src={littledistrict} className=""/>
            <div className="text-xl font-semibold pt-2">
              little district books, capitol hill in washington dc (6/17/23)
            </div>
            <div>
              2/5 stars for nonfiction; 2/5 stars for fiction. 
            </div>
          </div>
          
          <div className="space-y-3">
            <div className="text-pink-600 font-bold">[need to go back and get a photo]</div>
            {/* <img src={porterboston} className=""/> */}
            <div className="text-xl font-semibold pt-2">
              kramers, dupont circle in washington dc (3/28/23)
            </div>
            <div>
              2/5 stars for nonfiction; 3/5 stars for fiction.
            </div>
          </div>

        </div>

        <div className="space-y-12">

          <div className="space-y-3">
            <img src={capitolhill} className=""/>
            <div className="text-xl font-semibold pt-2">
              capitol hill books, capitol hill in washington dc (7/8/23)
            </div>
            <div>
              3/5 stars for nonfiction; 3/5 stars for fiction. 
            </div>
          </div>

          <div className="space-y-3">
            <img src={eastcity} className=""/>
            <div className="text-xl font-semibold pt-2">
              east city bookshop, capitol hill in washington dc (6/17/23)
            </div>
            <div>
              2/5 stars for nonfiction; 4/5 stars for fiction. 
            </div>
          </div>

          <div className="space-y-3">
            <img src={bridgest} className=""/>
            <div className="text-xl font-semibold pt-2">
              bridge street books, georgetown in washington dc (6/11/23)
            </div>
            <div>
              2/5 stars for nonfiction; 2/5 stars for fiction. 
            </div>
          </div>

        </div>

      </div>


      {/* CALIFORNIA */}
      <div className="text-xl font-bold mt-12 mb-6">
        california
      </div>

      <div className="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-16">

          <div className="space-y-12">

            <div className="space-y-3">
              <img src={last} className=""/>
              <div className="text-xl font-semibold pt-2">
                the last bookstore, los angeles CA (12/29/23)
              </div>
              <div>
                2/5 stars for nonfiction; 3/5 stars for fiction. 
              </div>
            </div>

            <div className="space-y-3">
              <img src={booksinc} className=""/>
              <div className="text-xl font-semibold pt-2">
                books inc., san francisco CA (8/20/23)
              </div>
              <div>
                2/5 stars for nonfiction; 2/5 stars for fiction. 
              </div>
            </div>

            <div className="space-y-3">
              <img src={arvida} className=""/>
              <div className="text-xl font-semibold pt-2">
                arvida book co., tustin CA (6/4/22)
              </div>
              <div>
                2/5 stars for nonfiction; 2/5 stars for fiction. Bought a used copy of <span className="italic">Freedom</span> by Jonathan Franzen.
              </div>
            </div>

          </div>

          <div className="space-y-12">

            <div className="space-y-3">
              <img src={dogeared} className=""/>
              <div className="text-xl font-semibold pt-2">
                dog eared books, san francisco CA (8/22/23)
              </div>
              <div>
                3/5 stars for nonfiction; 3/5 stars for fiction. 
              </div>
            </div>

            <div className="space-y-3">
              <img src={citylights} className=""/>
              <div className="text-xl font-semibold pt-2">
                city lights booksellers, san francisco CA (8/19/23)
              </div>
              <div>
                4/5 stars for nonfiction; 3/5 stars for fiction. 
              </div>
            </div>

          </div>

        </div>


      {/* ELSEWHERE */}
      <div className="text-xl font-bold mt-12 mb-6">
        elsewhere
      </div>

      <div className="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-16">

          <div className="space-y-12">

            <div className="space-y-3">
              <img src={bertrand} className=""/>
              <div className="text-xl font-semibold pt-2">
                librarie bertrand, montreal québec canada (3/25/24)
              </div>
              <div>
                2/5 stars for nonfiction; 2/5 stars for fiction. 
              </div>
            </div>

            <div className="space-y-3">
              <img src={maktaba} className=""/>
              <div className="text-xl font-semibold pt-2">
                maktaba bookshop, montreal québec canada (3/24/24)
              </div>
              <div>
                3/5 stars for nonfiction; 3/5 stars for fiction. 
              </div>
            </div>

            <div className="space-y-3">
              <img src={symposium} className=""/>
              <div className="text-xl font-semibold pt-2">
                symposium books, providence RI (2/18/23)
              </div>
              <div>
                2/5 stars for nonfiction; 4/5 stars for fiction. 
              </div>
            </div>

          </div>

          <div className="space-y-12">

            <div className="space-y-3">
              <img src={capitaine} className=""/>
              <div className="text-xl font-semibold pt-2">
              capitaine quebec, montreal québec canada (3/25/24)
              </div>
              <div>
                2/5 stars for nonfiction; 2/5 stars for fiction. 
              </div>
            </div>
            
            <div className="space-y-3">
              <img src={athenaeum} className=""/>
              <div className="text-xl font-semibold pt-2">
                providence athenaeum, providence RI (2/18/23)
              </div>
              <div>
                2/5 stars for nonfiction; 1/5 stars for fiction. 
              </div>
            </div>

          </div>

        </div>

    </Layout>
  )
}