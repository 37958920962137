import * as React from "react"
import Layout from '../../components/layout'
import { Link } from "gatsby"

export default function Classes() {
  return(
    <Layout>

      <Link to="/" state={{ item: 'writing' }} className="flex space-x-2 items-center hover:underline mb-7">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <div >
          back to all writing
        </div>
      </Link>

      <div className="text-2xl font-bold mb-2">
        Complete list of classes I've taken at MIT!
      </div>

      <div className="text-gray-600 mb-6">
        last updated december 2024
      </div>

        <div className="space-y-4">

        <div className="font-semibold">
          Fall 2024:
        </div>
        <div className="space-y-1 pb-4">
          <li>
            Mobility Ventures (11.029)
          </li>
          <li>
            Economic Analysis for Business Decisions (15.011)
          </li>
          <li>
            Introduction to Latin American Studies (21H.170)
          </li>
          <li>
            Hip-Hop (21T.420)
          </li>
          <li>
          Thesis Research Design Seminar (11.THT)
          </li>
        </div>

        <div className="font-semibold">
          Spring 2024:
        </div>
        <div className="space-y-1 pb-4">
          <li>
            Data Visualization and Society (11/6.C35)
          </li>
          <li>
            Leadership: People, Products, Projects (6.S630)
          </li>
          <li>
            Marketing Innovation (15.8141)
          </li>
          <li>
            Management Communication for Undergraduates (15.279)
          </li>
          <li>
            Product Management and Society (Harvard – DPI 678M)
          </li>
          <li>
            Introduction to Computational Thinking and Data Science (6.100B)
          </li>
        </div>

        <div className="font-semibold">
          Fall 2023:
        </div>
        <div className="space-y-1 pb-4">
          <li>
            Software Design (6.104/170)
          </li>
          <li>
            Leadership in Negotiation: Advanced Applications (11.111)
          </li>
          <li>
            Public Policy for Washington Interns (17.307)
          </li>
          <li>
            Asian American History (21H.107)
          </li>
          <li>
            Undergraduate Urban Planning Seminar (11.008)
          </li>
        </div>

        <div className="font-semibold">
          Spring 2023:
        </div>
        <div className="space-y-1 pb-4">
          <li>
            Introduction to Machine Learning (6.390/036)
          </li>
          <li>
            Software Construction (6.102/031)
          </li>
          <li>
            Introduction to Spatial Analysis and GIS (11.188)
          </li>
          <li>
            Modern Southeast Asia (Harvard – History 1037)
          </li>
        </div>

        <div className="font-semibold">
          Fall 2022:
        </div>
        <div className="space-y-1 pb-4">
          <li>
            Introduction to Algorithms (6.121/006)
          </li>
          <li>
            Linear Algebra and Optimization (18.C06)
          </li>
          <li>
            Introduction to Urban Design and Development (11.001)
          </li>
          <li>
            The Art and Science of Negotiation (11.011)
          </li>
          <li>
            Introduction to Computational Thinking in Cities (11.100)
          </li>  
          <li>
            Undergraduate Urban Planning Seminar (11.S187)
          </li>         
        </div>

        <div className="font-semibold">
          Summer 2022:
        </div>
        <div className="space-y-1 pb-4">
          <li>
            Experiential Ethics (24.133)
          </li>     
        </div>

        <div className="font-semibold">
          Spring 2022:
        </div>
        <div className="space-y-1 pb-4">
          <li>
            Fundamentals of Programming (6.009)
          </li>
          <li>
            Mathematics for Computer Science (6.042)
          </li>
          <li>
            Physics II: Electricity and Magnetism (8.02)
          </li>
          <li>
            Data and Society (11.155)
          </li>
          <li>
            Entrepreneurial Founding and Teams (15.3941)
          </li>        
        </div>

        <div className="font-semibold">
          Fall 2021:
        </div>
        <div className="space-y-1">
          <li>
            Multivariable Calculus (18.02)
          </li>
          <li>
            Introduction to Solid-State Chemistry (3.091)
          </li>
          <li>
            Physics I: Classical Mechanics (8.01)
          </li>
          <li>
            Introduction to Comparative Politics (17.50)
          </li>
          <li>
            Environmental Justice: An Introduction to Civil Rights and Environmental Law (11.A13)
          </li>
          <li>
            Economics and Society's Toughest Problems (14.009)
          </li>
          <li>
            Introduction to Computer Science and Programming in Python (6.0001 – credit by exam)
          </li>
          <li>
            Introduction to Biology (7.012 – credit by exam)
          </li>
          <li>
            Single Variable Calculus (18.01 – credit by exam)
          </li>
          <li>
            Introduction to Probability and Statistics (18.05 – transfer credit)
          </li>
          
        </div>
      </div>

      <div className="h-12">
      </div>
      
    </Layout>
  )
}