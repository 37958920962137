import * as React from "react"
import kodless from '../images/kodless.png'
import wwhiimg from '../images/wwhi.png'
import distanceunkownimg from '../images/distanceunknown.png'
import deepglioimg from '../images/deepglio.png'
import ensembleimg from '../images/ensemble.png'
import effectivityimg from '../images/effectivity.png'
import deepglio from '../files/CamachoDeepGlio.pdf'
import ensemble from '../files/CamachoEnsembleCNS.pdf'
import effectivity from '../files/CamachoCancerDrugEffectivity.pdf'

export default function Research() {
  return(
    // <div className="bg-slate-50 rounded-lg px-8 py-6">
      <div className="space-y-8">
        <h1 className="text-3xl font-bold">
          research
        </h1>
        <div className="space-y-6">
          <div className="space-y-8">
            <a href="https://www.youtube.com/watch?v=WgOhtH3lugk&list=PLY_nsiUEgM3nbxcO3_ONmKL-ZRhLKpfGV">
              <div className="sm:grid sm:grid-cols-3 bg-white shadow hover:shadow-lg rounded-lg border-t border-gray-100">
                <div className="col-span-2 px-8 py-6">
                  <div className="text-lg font-semibold mb-2">
                    Kodless: A Prototype App Generator
                  </div>
                  <div className="text-sm mb-2">
                    2024, Software Design Group at MIT CSAIL
                  </div>
                  <div>
                    Helped build Kodless, an app generator that uses GPT to produce custom full-stack web app code through concept-based design frameworks. 
                  </div>
                </div>
                <div className="hidden md:flex md:items-center bg-white border-l rounded-r-lg">
                  <img src={kodless} className="rounded-r-lg" />
                </div>
              </div>
            </a>
          </div>
          <div className="space-y-8">
            <a href="http://dataplusfeminism.mit.edu">
              <div className="sm:grid sm:grid-cols-3 bg-white shadow hover:shadow-lg rounded-lg border-t border-gray-100">
                <div className="col-span-2 px-8 py-6">
                  <div className="text-lg font-semibold mb-2">
                    MMIW/P Grassroots Resource Map
                  </div>
                  <div className="text-sm mb-2">
                    2022–2023, Data + Feminism Lab at MIT
                  </div>
                  <div>
                    Collaborated with the Waking Women Healing Institute to co-design and build software for data collection & storytelling to eliminate violence against Indigenous women and girls.
                  </div>
                </div>
                <div className="hidden md:flex md:items-center bg-white border-l rounded-r-lg">
                  <img src={wwhiimg} className="rounded-r-lg" />
                </div>
              </div>
            </a>
          </div>
          <div className="space-y-8">
            <a href="https://civicdatadesignlab.mit.edu/DISTANCE-UNKNOWN">
              <div className="sm:grid sm:grid-cols-3 bg-white shadow hover:shadow-lg rounded-lg border-t border-gray-100">
                <div className="col-span-2 px-8 py-6">
                  <div className="text-lg font-semibold mb-2">
                    Distance Unknown: Risks and Opportunities of Migration in the Americas
                  </div>
                  <div className="text-sm mb-2">
                    2022, Civic Data Design Lab at MIT
                  </div>
                  <div>
                    Performed data analysis for and designed and built components of 'Distance Unknown', an interactive exhibition for the United Nations World Food Programme visualizing Central American migration data.
                  </div>
                </div>
                <div className="hidden md:flex md:items-center bg-white border-l rounded-r-lg">
                  <img src={distanceunkownimg} className="rounded-r-lg" />
                </div>
              </div>
            </a>
          </div>
          <div className="space-y-8">
            <a href={deepglio}>
              <div className="sm:grid sm:grid-cols-3 bg-white shadow hover:shadow-lg rounded-lg border-t border-gray-100">
                <div className="col-span-2 px-8 py-6">
                  <div className="text-lg font-semibold mb-2">
                  DeepGlio: A Novel Computational Pipeline for the Segmentation, Classification, and Characterization of Low and High-Grade Brain Tumors
                  </div>
                  <div className="text-sm mb-2">
                    2019–2020, advised by Dr. Xuexia Wang at UNT
                  </div>
                  <div>
                    Designed, built, and tested DeepGlio, a computational pipeline utilizing convolutional and deep neural networks to segment, classify, and characterize low and high-grade gliomas using deep learning methods and patient MRI, genetic, demographic, and survival data.
                  </div>
                </div>
                <div className="hidden md:flex md:items-center bg-white border-l rounded-r-lg">
                  <img src={deepglioimg} className="rounded-r-lg" />
                </div>
              </div>
            </a>
          </div>
          <div className="space-y-8">
            <a href={ensemble}>
              <div className="sm:grid sm:grid-cols-3 bg-white shadow hover:shadow-lg rounded-lg border-t border-gray-100">
                <div className="col-span-2 px-8 py-6">
                  <div className="text-lg font-semibold mb-2">
                  Predicting the Development of Secondary Central Nervous System Cancer Through Ensemble Learning Methods
                  </div>
                  <div className="text-sm mb-2">
                    2018–2019, conducted under Dr. Xuexia Wang at UNT
                  </div>
                  <div>
                    Developed a computational method for the prediction of secondary central nervous system cancer through ensemble machine learning approaches utilizing both clinical and genetic data curated through multiple feature selection methods.
                  </div>
                </div>
                <div className="hidden md:flex md:items-center bg-white border-l rounded-r-lg">
                  <img src={ensembleimg} className="rounded-r-lg" />
                </div>
              </div>
            </a>
          </div>
          <div className="space-y-8">
            <a href={effectivity}>
              <div className="sm:grid sm:grid-cols-3 bg-white shadow hover:shadow-lg rounded-lg border-t border-gray-100">
                <div className="col-span-2 px-8 py-6">
                  <div className="text-lg font-semibold mb-2">
                    A Computational Method for Predicting Cancer Drug Effectivity Using Machine Learning Techniques
                  </div>
                  <div className="text-sm mb-2">
                    2017–2018, conducted independently
                  </div>
                  <div>
                    Used machine learning to predict drug effectivity through IC50 values based on the genomic and physicochemical characteristics of cell lines and drugs in order to speed up drug development processes and create more effective compounds.
                  </div>
                </div>
                <div className="hidden md:flex md:items-center bg-white border-l rounded-r-lg">
                  <img src={effectivityimg} className="rounded-r-lg"/>
                </div>
              </div>
            </a>
          </div>
        </div>

      </div>
    // </div>
  )
}
